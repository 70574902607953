module.exports = Object.freeze({
  MODAL_BODY_TYPES: {
    USER_DETAIL: 'USER_DETAIL',
    VISIT_ADD_NEW: 'VISIT_ADD_NEW',
    VISIT_OVERVIEW: 'VISIT_OVERVIEW',
    VISIT_OVERVIEW_COMPANY: 'VISIT_OVERVIEW_COMPANY',
    VISIT_OVERVIEW_AFFILIATE: 'VISIT_OVERVIEW_AFFILIATE',
    VISIT_OVERVIEW_CANCELATION_MODAL: 'VISIT_OVERVIEW_CANCELATION_MODAL',
    VISIT_OVERVIEW_ACTIVATION_MODAL: 'VISIT_OVERVIEW_ACTIVATION_MODAL',
    VISIT_OVERVIEW_CHECKIN_MODAL: 'VISIT_OVERVIEW_CHECKIN_MODAL',
    REVIEW_ENROLLMENT: 'REVIEW_ENROLLMENT',
    PHYSICIAN_CHECKIN_MODAL: 'PHYSICIAN_CHECKIN_MODAL',
    CHAT_SNIPPETS_MODAL: 'CHAT_SNIPPETS_MODAL',
    CHAT_SNIPPETS_CREATE_MODAL: 'CHAT_SNIPPETS_CREATE_MODAL',
    SUPPORT_CHAT_SNIPPETS: 'SUPPORT_CHAT_SNIPPETS',
    SUPPORT_CHAT_STAFF: 'SUPPORT_CHAT_STAFF',
    PAST_VISIT_DETAIL: 'PAST_VISIT_DETAIL',
    CONFIRMATION: 'CONFIRMATION',
    DEFAULT: '',
  },

  RIGHT_DRAWER_TYPES: {
    NOTIFICATION: 'NOTIFICATION',
    VISIT_CHAT: 'VISIT_CHAT',
  },

  CONFIRMATION_MODAL_CLOSE_TYPES: {
    VISIT_ADD: 'VISIT_ADD',
    PASTVISIT_DELETE: 'PASTVISIT_DELETE',
    VISIT_COMPLETE: 'VISIT_COMPLETE',
  },

  ENROLLMENT_STATUS: {
    NULL: 'NULL',
    IN_PROGRESS: 'IN PROGRESS',
    ATTENTION: 'ATTENTION',
    COMPLETE: 'COMPLETE',
  },
  INTAKE_STATUS: {
    NULL: 'NULL',
    IN_PROGRESS: 'IN PROGRESS',
    ATTENTION: 'ATTENTION',
    COMPLETE: 'COMPLETE',
  },
  UDS_STATUS: {
    NULL: 'NULL',
    IN_PROGRESS: 'IN PROGRESS',
    ATTENTION: 'ATTENTION',
    COMPLETE: 'COMPLETE',
  },
  REVIEW_STATUS: {
    NULL: 'NULL',
    IN_PROGRESS: 'IN PROGRESS',
    WAITING_AFFILIATE: 'WAITING FOR AFFILIATE',
    COMPLETE: 'COMPLETE',
  },

  VIDEO_STATUS: {
    NULL: 'NULL',
    WAITING_ROOM: 'WAITING ROOM',
    COMPLETE: 'COMPLETE',
  },

  VISIT_CANCELLATION_REASONS: {
    PATIENT_LEFT: 'Patient left',
    WRONG_PATIENT: 'Visit created for the wrong patient',
    MISTAKE: 'Visit created by mistake',
    DUPLICATE: 'This is a duplicate visit',
    OTHER: 'Other',
  },
})
